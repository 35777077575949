import register from "@/services/register.service";
import login from "@/services/login.service";
import emailExist from "@/services/emailExist.service";
import router from "@/router";
import jwt_decode from "jwt-decode";
import user from "./user";
import updatePassword from "@/services/updatePassword.service";
/* eslint-disable */
export default {
  state: {
    registerStep: 1,
    data: {},
    msg: {},
    form: {},
    email: "",
    password: ""
  },
  mutations: {
    setNewUserData(state, data) {
      state.data = data;
    },
    setMsg(state, msg) {
      state.msg = msg;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setPassword(state, password) {
      state.password = password;
    },
    incrementRegisterStep(state) {
      if (state.registerStep == 1) {
        state.registerStep++;
      }
    },
    decrementRegisterStep(state) {
      if (state.registerStep == 2) {
        state.registerStep--;
      }
    },
  },
  getters: {
    getMsg(state) {
      return state.msg;
    },
    getForm(state) {
      return state.form;
    },
    getEmail(state) {
      return state.email;
    },
    getPassword(state) {
      return state.password;
    },
    getRegisterStep(state) {
      return state.registerStep;
    },
    getData(state) {
      return state.data;
    },
    setForm(state, form) {
      state.form = form;
    }
  },
  actions: {
    async registerUser({ commit, state }, payload) {
      commit("setMsg", null);  
      const response = await register.getRegister({name: state.data.name,
        lastName: state.data.lastName,
        role: state.data.role,
        phone: state.data.phone,
        email: state.data.email,
        password: payload.password}); 
    },
    async loginUser({ commit }, payload) {
      const response = await login.getLogin(payload);
      if (response.status) {
        console.log(response.data.token);
        localStorage.setItem('token', response.data.token);
        const userData = jwt_decode(response.data.token);
        console.log(userData);
        commit(
          "setUserData",
          {
            id: userData.id,
            firstName: userData.name,
            lastName: userData.lastName,
            role: userData.roleId,
            //role: 1,
            phone: userData.phone,
            email: userData.email,
            client: userData.cliente
          },
          { root: true }
        );
        window.location.reload();
      } else {
        commit("setMsg", response);
      }
    },
    async loginUserFromRegister({ commit }, payload) {
      console.log(payload);
      const response = await login.getLogin(payload);
      if (response.status) {
        const userData = jwt_decode(response.data.token);
        commit(
          "setUserData",
          {
            id: userData.id,
            firstName: userData.name,
            lastName: userData.lastName,
            role: userData.roleId,
            phone: userData.phone,
            email: userData.email,
            client: userData.cliente
          },
          { root: true }
        );
        // Redirect to root
        router.push({ name: "Login" });
        
        
        //window.location.reload();
      } else {
        console.log(response);
      }
    },
    async emailExist({ commit }, payload) {
      commit("setMsg", null);  

      const response = await emailExist.getEmailExist({email: payload.email});
      if(response.data.success){
        commit('setNewUserData', payload);
        commit('incrementRegisterStep');
      } else { 
        commit("setMsg", response.data.message);
      }
    },
    async changePassword({ commit, rootState }, payload) {
      commit("setMsg", null);  
      try{
        const response = await updatePassword.newPassword({
          user_id: `${rootState.user.owner.id}`,
          password: payload.confirmPassword
        });
        commit("setMsg", { msg: response.data.message, variant: "success" });  
      } catch (err){
        commit("setMsg", { msg: "La nueva contraseña no puede ser igual a la contraseña actual.", variant: "danger" });
      } 
    },
  },
};
