<template>
  <b-navbar toggleable="lg" type="light" fixed="top" class="bg-white">
    <b-navbar-toggle
      v-b-toggle.sidebar-1
      target="nav-collapse"
      class="border-0"
    >
      <font-awesome-icon icon="fa-solid fa-bars" />
    </b-navbar-toggle>
    <b-navbar-nav class="ml-auto d-flex flex-row">
      <b-nav-item-dropdown menu-class="shadow" right>
        <template #button-content>
          <b-avatar
            class="mr-2"
            :size="avatarSize"
            :text="getInitialsName"
          ></b-avatar>
          <span class="d-none d-md-inline-block">{{ userName }}</span>
        </template>
        <!--
        <b-dropdown-item href="#" :to="{ name: 'Profile' }"
          >Mi cuenta</b-dropdown-item
        >
        <b-dropdown-item href="#" :to="{ name: 'PaymentCredits' }"
          >Mis créditos</b-dropdown-item
        >
        -->
        <b-dropdown-item href="#" @click="logout"
          >Cerrar sesión</b-dropdown-item
        >
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {
      userName: "",
      avatarSize: "32px",
    };
  },
  computed: {
    ...mapGetters(["getUserDataName", "getInitialsName", "getIsLoggedIn"]),
  },
  mounted() {
    this.userName = this.getUserDataName;
  },
  methods: {
    logout() {
      this.$store.commit("setLogout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
