<template>
    <div class="container">
      <b-card>
        <h4>Recepción de Dispositivos Médicos</h4>
        <b-card-text>
            Acá puede escanear el QR de un listado de dispositivos médicos de otro usurio y transferir su propiedad a ti.
        </b-card-text>
        <div>
          <!-- Selector de cámara -->
          <div class="mb-2 mt-2">
            <label for="cameraSelection">Seleccionar Cámara:</label>
            <select id="cameraSelection" @change="changeCamera" v-model="selectedCameraId" class="form-control d-inline-block w-auto">
              <option v-for="device in videoDevices" :key="device.id" :value="device.id">
                {{ device.label || `Cámara ${device.id}` }}
              </option>
            </select>
          </div>
          <!-- Botón para iniciar el escaneo -->
          <b-button @click="startQrScan" variant="primary"><i class="fa fa-qrcode"></i> Escanear QR de Recepción</b-button>
        </div>
  
        <!-- Contenedor del lector QR -->
        <div v-show="selectCameraMode" class="text-center mt-3">
          <div id="qr-reader" class="video-fluid"></div>
          <!-- Botón para detener el escaneo -->
          <div class="mt-3 text-center">
            <b-button @click="stopQrScan" variant="secondary" class="w-100">
              Detener Escaneo
            </b-button>
          </div>
        </div>
  
        <!-- Lista de DM's escaneados -->
        <div v-if="transferDetails.length" class="mt-4">
          <h5>Dispositivos Médicos a Transferir:</h5>
          <b-table :items="transferDetails" :fields="fields" responsive="sm"></b-table>
          <b-button @click="confirmTransfer" variant="success">Confirmar Recepción</b-button>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { Html5Qrcode } from "html5-qrcode";
  import Config from "@/../config.json";

  export default {
    data() {
      return {
        transferDetails: [],
        selectedCameraId: null,
        videoDevices: [],
        selectCameraMode: false,
        qrCodeScanner: null,
        token: localStorage.getItem('token'),
        fields: ['Dispositivo', 'Cantidad', 'SN', 'LN', 'FV', 'owner'],
        listuuid: '', // Agrega una variable para almacenar el listuuid
      };
    },
    methods: {
      async getAvailableCameras() {
        try {
          const devices = await Html5Qrcode.getCameras();
          this.videoDevices = devices;
  
          if (this.videoDevices.length > 0 && !this.selectedCameraId) {
            this.selectedCameraId = this.videoDevices[0].id;
          }
        } catch (error) {
          console.error("Error obteniendo cámaras:", error);
        }
      },
      async startQrScan() {
        this.selectCameraMode = true;

        await this.$nextTick();

        if (this.qrCodeScanner) {
            await this.stopQrScan();
        }

        const qrReaderElement = document.getElementById("qr-reader");
        if (!qrReaderElement) {
            console.error("El elemento 'qr-reader' no existe en el DOM.");
            return;
        }

        this.qrCodeScanner = new Html5Qrcode("qr-reader");

        const config = { fps: 10, qrbox: 250 };

        try {
            await this.qrCodeScanner.start(
            this.selectedCameraId,
            config,
            async (decodedText) => {
                console.log("QR Code escaneado: ", decodedText);

                const { listuuid } = JSON.parse(decodedText.trim());
                this.listuuid = listuuid; // Guardar el listuuid escaneado

                // Obtener los detalles de la lista escaneada desde S3
                try {
                const response = await axios.post(`${Config.TRAZAMED_APP_URL_BASE}/getassetlist`, {
                    uuid: listuuid
                }, {
                    headers: {
                    'Authorization': `Bearer ${this.token}`
                    }
                });

                if (response.data.assetList) {
                    this.transferDetails = response.data.assetList;
                }
                } catch (error) {
                console.error('Error obteniendo los detalles de la lista:', error);
                alert('Error obteniendo los detalles de la lista.');
                }

                // Detener el escáner después de procesar el QR
                await this.stopQrScan();
            },
            (errorMessage) => {
                console.warn("Error de escaneo: ", errorMessage);
            }
            );
        } catch (err) {
            console.error("Error iniciando el lector QR: ", err);
        }
      },
      async stopQrScan() {
        if (this.qrCodeScanner) {
          await this.qrCodeScanner.stop();
          this.qrCodeScanner.clear();
          this.qrCodeScanner = null;
        }
        this.selectCameraMode = false;
      },
      async changeCamera() {
        if (this.selectCameraMode) {
          await this.stopQrScan();
          this.startQrScan();
        }
      },
      async confirmTransfer() {
        if (!this.transferDetails.length) {
            alert('No hay DMs para confirmar la recepción.');
            return;
        }

        try {
            const response = await axios.post(`${Config.TRAZAMED_APP_URL_BASE}/transferOwnership`, {
            listuuid: this.listuuid, // Enviar el listuuid guardado aquí
            }, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
            });

            if (response.status === 200) {
            alert('Propiedad transferida exitosamente.');
            } else {
            alert('Error durante la transferencia de propiedad.');
            }
        } catch (error) {
            console.error('Error confirmando la recepción:', error);
            alert('Error confirmando la recepción.');
        }
      }
    },
    created() {
      this.getAvailableCameras();
    },
    beforeDestroy() {
      if (this.qrCodeScanner) {
        this.qrCodeScanner.clear();
        this.qrCodeScanner = null;
      }
    }
  };
  </script>
  
  <style scoped>
  #qr-reader {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  .video-fluid {
    width: 100%;
    height: auto;
  }
  .qr-general {
    margin: 20px 0;
  }
  </style>
  