<template>
    <div class="container">
      <!-- ... existing code ... -->
  
      <!-- New Section: User's Lists -->
      <div v-if="userLists.length">
        <h3>Tus Listados de Dispositivos</h3>
        <table class="table">
            <thead>
                <tr>
                <th>UUID</th>
                <th>Fecha de Creación</th>
                <th>Proveedor</th>
                <th>Número de Factura</th>
                <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="list in userLists" :key="list.uuid">
                <td>{{ list.uuid }}</td>
                <td>{{ formatDate(list.creation_date) }}</td>
                <td>{{ list.proveedor || 'N/A' }}</td>
                <td>{{ list.numeroFactura || 'N/A' }}</td>
                <td>
                    <button @click="viewList(list.uuid)" class="btn btn-primary">Ver</button>
                </td>
                </tr>
            </tbody>
            </table>

      </div>
  
      <!-- ... rest of your existing code ... -->
    </div>
  </template>
  
  <script>
  import vuejsDatepicker from 'vuejs-datepicker';
  import { es } from 'vuejs-datepicker/dist/locale';
  import VueQrcodeComponent from 'vue-qrcode-component';
  import Config from "@/../config.json";
  import JsBarcode from 'jsbarcode';
  import { Html5Qrcode, Html5QrcodeScannerState } from "html5-qrcode";
  
  export default {
    components: {
      vuejsDatepicker,
      VueQrcode: VueQrcodeComponent
    },
    data() {
      return {
        // ... existing data properties ...
        userLists: [], // New: list of user's lists
        uuidInterval: null, // Interval for updating UUID
      };
    },
    methods: {
      // ... existing methods ...
  
      async fetchAssetList() {
        this.loading = true;
        try {
          const token = localStorage.getItem('token');
  
          // Get the last UUID of the user
          const uuidResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getUserLastUUID`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
  
          if (uuidResponse.ok) {
            const uuidData = await uuidResponse.json();
            this.listuuid = uuidData.last_uuid;
          } else {
            // If no previous UUID, generate a new one
            this.listuuid = this.generateUUID();
          }
  
          localStorage.setItem('listuuid', this.listuuid);
  
          const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getassetlist`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ uuid: this.listuuid })
          });
  
          if (response.ok) {
            const result = await response.json();
            this.invoiceData = result.assetList.map(item => ({ ...item, editing: false }));
          } else if (response.status === 404) {
            this.invoiceData = [];
          } else {
            console.error('Error fetching asset list:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching asset list:', error);
        } finally {
          this.loading = false;
        }
      },
  
      async updateListUUID() {
        try {
          const token = localStorage.getItem('token');
  
          const uuidResponse = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getUserLastUUID`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
  
          if (uuidResponse.ok) {
            const uuidData = await uuidResponse.json();
            if (this.listuuid !== uuidData.last_uuid) {
              this.listuuid = uuidData.last_uuid;
              localStorage.setItem('listuuid', this.listuuid);
              this.fetchAssetList(); // Update the asset list
            }
          }
        } catch (error) {
          console.error('Error updating list UUID:', error);
        }
      },
  
      async fetchUserLists() {
        try {
          const token = localStorage.getItem('token');
  
          const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/getUserLists`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
  
          if (response.ok) {
            const data = await response.json();
            this.userLists = data.lists;
          } else {
            console.error('Error fetching user lists:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching user lists:', error);
        }
      },
  
      formatDate(timestamp) {
        const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
        return date.toLocaleDateString();
      },
  
      async viewList(uuid) {
        try {
            const token = localStorage.getItem('token');

            // Llamar al endpoint para establecer el UUID del usuario
            const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/setUserLastUUID`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ uuid })
            });

            if (response.ok) {
                // Si el UUID se actualiza correctamente, redirigir a CreateAssetList
                this.listuuid = uuid;
                localStorage.setItem('listuuid', this.listuuid);
                this.$router.push({ name: 'CreateAssetList' });
            } else {
                console.error('Error setting user last UUID:', response.statusText);
            }
        } catch (error) {
            console.error('Error setting user last UUID:', error);
        }
      },
      // ... rest of your methods ...
    },
    created() {
      this.fetchAssetList();
      this.fetchUserLists();
  
      // Interval to update the UUID every 5 minutes (300000 ms)
      this.uuidInterval = setInterval(() => {
        this.updateListUUID();
      }, 300000); // 5 minutes
    },
    beforeDestroy() {
      // Clear the interval when the component is destroyed
      clearInterval(this.uuidInterval);
    },
    // ... rest of your component ...
  };
  </script>
  
  <style scoped>
  /* ... your existing styles ... */
  </style>
  