<template>
  <div class="inventory-list">
    <b-card :title="cardTitle">
      <b-form inline class="mb-3">
        <b-form-group label="Buscar por nombre, SKU, EAN o fabricante:" label-for="input-search-name">
          <b-form-input id="input-search-name" v-model="searchTerm" placeholder="Buscar" />
        </b-form-group>

        <!-- Filtro por categoría -->
        <b-form-group label="Filtrar por categoría:" class="ml-3">
          <b-form-select v-model="filterCategory" :options="categoryOptions" />
        </b-form-group>

        <!-- Filtro por bodega -->
        <b-form-group label="Filtrar por bodega:" class="ml-3">
          <b-form-select v-model="filterWarehouse" :options="warehouseOptions" />
        </b-form-group>

        <!-- Checkbox para mostrar todos los productos -->
        <b-form-group class="ml-3">
          <b-form-checkbox v-model="showAllStatuses" name="show-all-statuses">
            {{ showAllStatuses ? 'Mostrar productos inactivos (sin comunicación o fuera de zonas)' : 'Mostrar productos inactivos (sin comunicación o fuera de zonas)' }}
          </b-form-checkbox>
        </b-form-group>
      </b-form>

      <!-- Tabla de inventario -->
      <b-table striped hover :items="filteredItems" :fields="fields">
        <!-- Mostrar fabricante -->
        <template #cell(manufacturer)="data">
          {{ data.item.manufacturer || 'Sin información' }}
        </template>

        <template #cell(category)="data">
          {{ data.item.category || 'Sin información' }}
        </template>

        <template #cell(warehouse)="data">
          {{ data.item.warehouse }}
        </template>

        <template #cell(quantity)="data">
          {{ data.item.quantity }}
        </template>

        <!-- Columna con botón Detalles -->
        <template #cell(detalles)="data">
          <b-button variant="primary" @click="showDetails(data.item)">Detalles</b-button>
        </template>
      </b-table>
    </b-card>
    <!-- Modal para mostrar detalles de activos -->
    <b-modal
      id="details-modal"
      :title="'Detalles de ' + (selectedItem ? selectedItem.producto : '')"
      size="xl"
      @hide="resetModalFilters"
    >
      <!-- Mostrar cantidad total de activos -->
      <p v-if="activos.length">Cantidad total de activos: {{ filteredActivos.length }}</p>
      <p v-else>No hay activos para mostrar.</p>

      <!-- Filtros en el modal -->
      <b-form inline class="mb-3" v-if="activos.length">
        <b-form-group label="Filtrar por estado:" class="ml-3">
          <b-form-select v-model="modalFilterStatus" :options="statusOptions" />
        </b-form-group>

        <b-form-group class="ml-3">
          <b-form-checkbox v-model="modalEnableDateFilter" name="modal-enable-date-filter">
            Habilitar filtro por fecha
          </b-form-checkbox>
        </b-form-group>

        <b-form-group class="ml-3" v-if="modalEnableDateFilter">
          <b-form-select v-model="modalFilterOption" :options="filterDateOptions" />
        </b-form-group>

        <b-form-group class="ml-3 mt-2" label="Fecha personalizada" v-if="modalFilterOption === 'custom' && modalEnableDateFilter">
          <b-form-input class="ml-1" type="date" v-model="modalCustomDate" />
        </b-form-group>

        <b-form-group class="ml-3 mt-2" label="Rango de fechas" v-if="modalFilterOption === 'range' && modalEnableDateFilter">
          <b-form-input class="ml-1" type="date" v-model="modalStartDate" placeholder="Fecha de inicio" />
          <b-form-input class="ml-1" type="date" v-model="modalEndDate" placeholder="Fecha de fin" />
        </b-form-group>

        <b-form-group label="Filtrar por localización:" class="ml-3">
          <b-form-select v-model="modalFilterLocation" :options="locationOptions" />
        </b-form-group>

        <b-form-group label="Filtrar por zona:" class="ml-3">
          <b-form-select v-model="modalFilterZone" :options="zoneOptions" />
        </b-form-group>
      </b-form>

      <!-- Tabla de detalles de activos -->
      <b-table
        striped
        hover
        :items="filteredActivos"
        :fields="modalFields"
        v-if="activos.length"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <!-- Personalizar el encabezado de la columna "Vencimiento" -->
        <template #head(expirationDate)="data">
          <div class="d-flex align-items-center">
            <span>Vencimiento</span>
            <b-button
              size="sm"
              variant="link"
              @click="toggleSortOrder"
              class="ml-2 p-0"
            >
              <i :class="sortDesc ? 'fas fa-arrow-down' : 'fas fa-arrow-up'"></i>
            </b-button>
          </div>
        </template>

        <!-- Mostrar la fecha formateada en la celda -->
        <template #cell(expirationDate)="data">
          {{ formatDate(data.item.expirationDate) }}
        </template>
      </b-table>

      <template #modal-footer="{ hide }">
        <b-button variant="secondary" @click="hide">Cerrar</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
// Importa Config si está definido en otro archivo
// import Config from '@/config';
import Config from "@/../config.json";

export default {
  data() {
    return {
      // Propiedades para el ordenamiento
      sortBy: 'expiration',
      sortDesc: false, // false para orden ascendente
      items: [],
      searchTerm: '', // Buscar por nombre, SKU, EAN o fabricante
      filterCategory: 'todos',
      filterWarehouse: 'todos',
      showAllStatuses: false, // Nueva propiedad para el estado del checkbox
      fields: [
        { key: 'producto', label: 'Producto' },
        { key: 'manufacturer', label: 'Fabricante' }, // Campo opcional: Fabricante
        { key: 'quantity', label: 'Cantidad' },
        { key: 'ean', label: 'EAN' }, // Campo opcional
        { key: 'sku', label: 'SKU' }, // Campo opcional
        { key: 'category', label: 'Categoría' }, // Campo opcional
        { key: 'warehouse', label: 'Bodega' },
        { key: 'detalles', label: 'Detalles' } // Nueva columna para el botón "Detalles"
      ],
      categoryOptions: [
        { value: 'todos', text: 'Todas' }
        // Las categorías se cargarán dinámicamente
      ],
      warehouseOptions: [
        { value: 'todos', text: 'Todas' }
        // Las bodegas se cargarán dinámicamente
      ],
      // Datos para el modal
      selectedItem: null,
      activos: [],
      // Filtros del modal
      modalFilterStatus: 'todos',
      statusOptions: [
        { value: 'todos', text: 'Todos' },
        { value: 'Operativo', text: 'Operativo' },
        { value: 'Mermado', text: 'Mermado' },
        { value: 'Vencido', text: 'Vencido' }
      ],
      modalEnableDateFilter: false,
      modalFilterOption: 'today',
      filterDateOptions: [
        { value: 'today', text: 'Hasta hoy' },
        { value: 'custom', text: 'Fecha personalizada' },
        { value: 'range', text: 'Rango de fechas' }
      ],
      modalCustomDate: '',
      modalStartDate: '',
      modalEndDate: '',
      modalFilterLocation: 'todos',
      modalFilterZone: 'todos',
      locationOptions: [
        { value: 'todos', text: 'Todas' }
        // Las localizaciones se cargarán dinámicamente
      ],
      zoneOptions: [
        { value: 'todos', text: 'Todas' }
        // Las zonas se cargarán dinámicamente
      ]
    };
  },
  computed: {
    cardTitle() {
      return this.filterWarehouse === 'todos' ? 'Inventario Total' : `Inventario ${this.filterWarehouse}`;
    },
    filteredItems() {
      // Procesar items para sumarlos por 'codigo' (si existe), 'producto', 'warehouse' y 'wstatus'
      let processedItems = [];
      let itemsMap = {};

      this.items.forEach(item => {
        if (
          (item.location && item.location.toLowerCase().includes('bodega')) ||
          (item.zone && item.zone.toLowerCase().includes('bodega'))
        ) {
          const warehouseName =
            item.warehouse || item.location || item.zone || 'Sin información';

          if (this.filterWarehouse !== 'todos' && warehouseName !== this.filterWarehouse) {
            return; // No coincide con el filtro de bodega
          }

          // Usar 'codigo' si existe, de lo contrario usar 'producto' como clave
          //const codeOrProduct = item.codigo || item.producto;
          // Incluir 'wstatus' en la clave para distinguir entre diferentes estados
          //const key = codeOrProduct + '_' + warehouseName + '_' + item.wstatus;
          const key = item.producto + '_' + warehouseName + '_' + item.wstatus;

          if (!itemsMap[key]) {
            itemsMap[key] = {
              codigo: item.codigo || '',
              producto: item.producto,
              manufacturer: item.manufacturer || 'Sin información',
              category: item.category || 'Sin información',
              warehouse: warehouseName,
              quantity: 0,
              ean: item.ean || '',
              sku: item.sku || '',
              wstatus: item.wstatus || ''
            };
          }

          itemsMap[key].quantity += parseFloat(item.quantity);
        }
      });

      processedItems = Object.values(itemsMap);

      return processedItems.filter(item => {
        const matchesSearch = this.searchTerm
          ? (item.producto || '')
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (item.sku || '')
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (item.ean || '')
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (item.manufacturer || '')
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          : true;

        let matchesCategory =
          this.filterCategory === 'todos' ? true : item.category === this.filterCategory;

        let matchesStatus = true;
        if (!this.showAllStatuses) {
          // Aplicar filtro por 'wstatus' igual a 'active'
          matchesStatus = item.wstatus === 'active';
        }

        return matchesSearch && matchesCategory && matchesStatus;
      });
    },
    filteredActivos() {
      const today = new Date().toISOString().split('T')[0];
      const defaultDate = new Date();
      defaultDate.setDate(defaultDate.getDate() + 7);
      const sevenDaysFromToday = defaultDate.toISOString().split('T')[0];

      return this.activos.filter(item => {
        let matchesStatus =
          this.modalFilterStatus === 'todos' ? true : item.status === this.modalFilterStatus;

        let matchesExpiration = true;
        if (this.modalEnableDateFilter) {
          if (this.modalFilterOption === 'today') {
            matchesExpiration = item.expiration <= today;
          } else if (this.modalFilterOption === 'custom') {
            const filterDate = this.modalCustomDate || sevenDaysFromToday;
            matchesExpiration = item.expiration <= filterDate;
          } else if (this.modalFilterOption === 'range') {
            matchesExpiration =
              this.modalStartDate &&
              this.modalEndDate &&
              this.modalStartDate <= this.modalEndDate &&
              item.expiration >= this.modalStartDate &&
              item.expiration <= this.modalEndDate;
          }
        }

        let matchesLocation =
          this.modalFilterLocation === 'todos' ? true : item.location === this.modalFilterLocation;

        let matchesZone =
          this.modalFilterZone === 'todos' ? true : item.zone === this.modalFilterZone;

        return matchesStatus && matchesExpiration && matchesLocation && matchesZone;
      });
    },
    // Actualizamos modalFields para habilitar la ordenación
    modalFields() {
      return [
        { key: 'status', label: 'Estado' },
        { key: 'codigo', label: 'Código' },
        { key: 'producto', label: 'Producto' },
        { key: 'manufacturer', label: 'Fabricante' },
        { key: 'ean', label: 'EAN' },
        { key: 'sku', label: 'SKU' },
        {
          key: 'expirationDate',
          label: 'Vencimiento',
          sortable: true,
          formatter: (value, key, item) => this.formatDate(value),
          sortByFormatted: false,
        },
        { key: 'location', label: 'Localización' },
        { key: 'zone', label: 'Zona' }
      ];
    },
  },
  methods: {
    toggleSortOrder() {
      if (this.sortBy === 'expirationDate') {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = 'expirationDate';
        this.sortDesc = false; // Por defecto, empezar con orden ascendente
      }
    },
    parseDate(value) {
      if (!value) return null;
      const parts = value.split('-');
      if (parts.length !== 3) return null;
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Los meses en JavaScript son 0-based
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    },
    formatDate(date) {
      if (!date || !(date instanceof Date)) return 'Sin fecha';
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async loadItems() {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${Config.TRAZAMED_APP_URL_BASE}/get_inventory_items`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        this.items = data.items;

        // Generar las opciones de bodegas y categorías dinámicamente
        const warehouseSet = new Set();
        const categorySet = new Set();

        this.items.forEach(item => {
          const warehouseName =
            item.warehouse || item.location || item.zone || 'Sin información';
          if (
            (item.location && item.location.toLowerCase().includes('bodega')) ||
            (item.zone && item.zone.toLowerCase().includes('bodega'))
          ) {
            warehouseSet.add(warehouseName);
          }

          if (item.category) {
            categorySet.add(item.category);
          }
        });

        // Resetea las opciones antes de llenarlas
        this.warehouseOptions = [{ value: 'todos', text: 'Todas' }];
        this.categoryOptions = [{ value: 'todos', text: 'Todas' }];

        if (warehouseSet.size === 0) {
          this.warehouseOptions.push({
            value: 'Sin información',
            text: 'Sin información'
          });
        } else {
          warehouseSet.forEach(warehouse => {
            this.warehouseOptions.push({ value: warehouse, text: warehouse });
          });
        }

        if (categorySet.size === 0) {
          this.categoryOptions.push({ value: 'Sin información', text: 'Sin información' });
        } else {
          categorySet.forEach(category => {
            this.categoryOptions.push({ value: category, text: category });
          });
        }
      } else {
        console.error('Error al obtener inventario:', response.statusText);
      }
      } catch (error) {
        console.error('Error al obtener inventario:', error);
      }
    },
    showDetails(item) {
      if (!item) {
        console.error('No se seleccionó ningún item.');
        return;
      }

      this.selectedItem = item;
      this.activos = this.items.filter(activo => {
        const warehouseName =
          activo.warehouse || activo.location || activo.zone || 'Sin información';

        // Incluir 'wstatus' en la clave
        const key = activo.producto + '_' + warehouseName + '_' + activo.wstatus;
        const selectedKey = this.selectedItem.producto + '_' + this.selectedItem.warehouse + '_' + this.selectedItem.wstatus;

        return key === selectedKey;
      }).map(activo => {
        // Parsear la fecha de vencimiento y agregar 'expirationDate'
        const parsedDate = this.parseDate(activo.expiration);
        return {
          ...activo,
          expirationDate: parsedDate
        };
      });

      // Generar opciones de localización y zona
      const locationSet = new Set();
      const zoneSet = new Set();

      this.activos.forEach(activo => {
        if (activo.location) {
          locationSet.add(activo.location);
        }
        if (activo.zone) {
          zoneSet.add(activo.zone);
        }
      });

      this.locationOptions = [{ value: 'todos', text: 'Todas' }];
      this.zoneOptions = [{ value: 'todos', text: 'Todas' }];

      locationSet.forEach(location => {
        this.locationOptions.push({ value: location, text: location });
      });

      zoneSet.forEach(zone => {
        this.zoneOptions.push({ value: zone, text: zone });
      });

      this.$bvModal.show('details-modal');
    },
    resetModalFilters() {
      // Resetear filtros del modal al cerrarlo
      this.modalFilterStatus = 'todos';
      this.modalEnableDateFilter = false;
      this.modalFilterOption = 'today';
      this.modalCustomDate = '';
      this.modalStartDate = '';
      this.modalEndDate = '';
      this.modalFilterLocation = 'todos';
      this.modalFilterZone = 'todos';
    }
  },
  mounted() {
    this.loadItems();
    this.updateInterval = setInterval(() => {
      this.loadItems();
    }, 30000); // 30 segundos
  },
  beforeDestroy() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  },
};
</script>


<style scoped>
.inventory-list {
  margin-top: 20px;
}
.text-success i {
  color: green;
}
.text-warning i {
  color: orange;
}
.text-danger i {
  color: red;
}
</style>

