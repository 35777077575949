<template>
    <div class="inventory-adjust">
      <b-card title="Ajustes de Inventario">
        <b-form @submit.prevent="submitForm">
          <b-form-group label="Nombre del Producto">
            <b-form-input v-model="item.name" disabled></b-form-input>
          </b-form-group>
  
          <!-- Radio buttons to select adjustment type -->
          <b-form-group>
            <b-form-radio-group v-model="adjustmentType" :options="adjustmentOptions"></b-form-radio-group>
          </b-form-group>
  
          <!-- Conditionally show form for adjusting quantities -->
          <div v-if="adjustmentType === 'adjust'">
            <b-form-group label="Cantidad Actual en Bodega">
              <b-form-select v-model="selectedWarehouse" :options="warehouseOptions"></b-form-select>
            </b-form-group>
            <b-form-group label="Cantidad Disponible (No modificable)">
              <b-form-input v-model="currentQuantity" type="number" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Nueva Cantidad">
              <b-form-input v-model="newQuantity" type="number" required></b-form-input>
            </b-form-group>
          </div>
  
          <!-- Conditionally show form for movements -->
          <div v-if="adjustmentType === 'move'">
            <b-form-group label="Desde Bodega">
              <b-form-select v-model="warehouseFrom" :options="filteredFromWarehouseOptions"></b-form-select>
            </b-form-group>
            <b-form-group label="Cantidad Disponible (No modificable)">
              <b-form-input v-model="availableQuantityFrom" type="number" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Hacia Bodega">
              <b-form-select v-model="warehouseTo" :options="filteredToWarehouseOptions"></b-form-select>
            </b-form-group>
            <b-form-group label="Cantidad a Mover">
              <b-form-input v-model="moveQuantity" type="number" :max="availableQuantityFrom" required></b-form-input>
              <b-form-invalid-feedback v-if="moveQuantity > availableQuantityFrom">
                La cantidad a mover no puede exceder la cantidad disponible en la bodega seleccionada.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
  
          <b-button type="submit" variant="primary" :disabled="adjustmentType === 'move' && moveQuantity > availableQuantityFrom">
            Guardar
          </b-button>
          <b-button @click="$router.push('/inventory')" variant="danger" class="ml-2">Cancelar</b-button>
        </b-form>
      </b-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        item: {
          id: this.$route.params.id,
          name: '',
          warehouses: [] // Store warehouse-specific quantities
        },
        adjustmentType: 'adjust', // Default to "adjust quantities"
        adjustmentOptions: [
          { value: 'adjust', text: 'Ajustar cantidades' },
          { value: 'move', text: 'Movimiento' }
        ],
        selectedWarehouse: '', // For adjusting quantities
        currentQuantity: 0, // Current quantity for selected warehouse
        newQuantity: 0, // New quantity for selected warehouse
        warehouseFrom: '', // Source warehouse for movement
        warehouseTo: '', // Destination warehouse for movement
        availableQuantityFrom: 0, // Available quantity in the "From" warehouse
        moveQuantity: 0, // Quantity to move
        warehouseOptions: [
          { value: 'full_space', text: 'Bodega Full Space' },
          { value: 'kardex_hospitalizado', text: 'Bodega Kardex Hospitalizado' },
          { value: 'kardex_ambulatorio', text: 'Bodega Kardex Ambulatorio' },
          { value: 'recetas_alta', text: 'Bodega Recetas de Alta' },
          { value: 'mermas', text: 'Bodega Mermas' },
          { value: 'cuarentena', text: 'Bodega Cuarentena' }
        ]
      };
    },
    computed: {
      filteredFromWarehouseOptions() {
        return this.warehouseOptions.filter(option => option.value !== this.warehouseTo);
      },
      filteredToWarehouseOptions() {
        return this.warehouseOptions.filter(option => option.value !== this.warehouseFrom);
      }
    },
    methods: {
      loadItemDetails() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const item = inventoryItems.find(i => i.id == this.item.id);
        if (item) {
          this.item = { ...item };
        } else {
          this.$router.push('/inventory');
        }
      },
      updateCurrentQuantity() {
        const warehouse = this.item.warehouses.find(w => w.warehouse === this.selectedWarehouse);
        this.currentQuantity = warehouse ? warehouse.quantity : 0;
      },
      updateAvailableQuantityFrom() {
        const warehouse = this.item.warehouses.find(w => w.warehouse === this.warehouseFrom);
        this.availableQuantityFrom = warehouse ? warehouse.quantity : 0;
      },
      submitForm() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const index = inventoryItems.findIndex(i => i.id == this.item.id);
  
        if (index !== -1) {
          if (this.adjustmentType === 'adjust') {
            // Adjust quantities
            const warehouse = inventoryItems[index].warehouses.find(w => w.warehouse === this.selectedWarehouse);
            if (warehouse) {
              warehouse.quantity = this.newQuantity;
            }
  
            // Log the adjustment
            inventoryItems[index].movements = inventoryItems[index].movements || [];
            inventoryItems[index].movements.push({
              date: new Date().toISOString(),
              type: 'Cantidad Ajustada',
              quantity: this.newQuantity - this.currentQuantity,
              warehouse_from: this.selectedWarehouse,
              warehouse_to: this.selectedWarehouse
            });
          } else if (this.adjustmentType === 'move') {
            // Handle movement between warehouses
            const warehouseFrom = inventoryItems[index].warehouses.find(w => w.warehouse === this.warehouseFrom);
            const warehouseTo = inventoryItems[index].warehouses.find(w => w.warehouse === this.warehouseTo);
  
            if (warehouseFrom && warehouseTo && this.moveQuantity <= warehouseFrom.quantity) {
              warehouseFrom.quantity -= this.moveQuantity;
              warehouseTo.quantity += this.moveQuantity;
  
              // Log the movement
              inventoryItems[index].movements.push({
                date: new Date().toISOString(),
                type: 'Movimiento',
                quantity: this.moveQuantity,
                warehouse_from: this.warehouseFrom,
                warehouse_to: this.warehouseTo
              });
            }
          }
  
          // Save updated inventory
          localStorage.setItem('inventoryItems', JSON.stringify(inventoryItems));
          this.$router.push('/inventory');
        }
      }
    },
    watch: {
      selectedWarehouse() {
        this.updateCurrentQuantity(); // Update current quantity when selected warehouse changes
      },
      warehouseFrom() {
        this.updateAvailableQuantityFrom(); // Update available quantity when source warehouse changes
      }
    },
    created() {
      this.loadItemDetails();
    }
  }
  </script>
  
  <style scoped>
  .inventory-adjust {
    max-width: 600px;
    margin: auto;
  }
  </style>
  