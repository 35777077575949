<template>
    <div class="inventory-count">
      <b-card title="Recuento Físico de Inventario">
        <b-form @submit.prevent="submitCount">
          <b-form-group label="Nombre del Producto">
            <b-form-input v-model="item.name" disabled></b-form-input>
          </b-form-group>
  
          <!-- Select the warehouse to count -->
          <b-form-group label="Seleccionar Bodega">
            <b-form-select v-model="selectedWarehouse" :options="warehouseOptions"></b-form-select>
          </b-form-group>
  
          <b-form-group label="Cantidad Actual en Bodega">
            <b-form-input v-model="currentQuantity" type="number" disabled></b-form-input>
          </b-form-group>
          <b-form-group label="Cantidad Física">
            <b-form-input v-model="physicalCount" type="number" required></b-form-input>
          </b-form-group>
          <b-form-group label="Diferencia">
            <b-form-input :value="difference" type="number" disabled></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Guardar Recuento</b-button>
          <b-button @click="$router.push('/inventory')" variant="danger" class="ml-2">Cancelar</b-button>
        </b-form>
      </b-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        item: {
          id: this.$route.params.id,
          name: '',
          warehouses: []
        },
        selectedWarehouse: '', // The warehouse to count
        currentQuantity: 0, // The current quantity in the selected warehouse
        physicalCount: 0, // The physical count of items
        warehouseOptions: [
          { value: 'full_space', text: 'Bodega Full Space' },
          { value: 'kardex_hospitalizado', text: 'Bodega Kardex Hospitalizado' },
          { value: 'kardex_ambulatorio', text: 'Bodega Kardex Ambulatorio' },
          { value: 'recetas_alta', text: 'Bodega Recetas de Alta' },
          { value: 'mermas', text: 'Bodega Mermas' },
          { value: 'cuarentena', text: 'Bodega Cuarentena' }
        ]
      };
    },
    computed: {
      difference() {
        return this.physicalCount - this.currentQuantity;
      }
    },
    methods: {
      loadItemDetails() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const item = inventoryItems.find(i => i.id == this.item.id);
        if (item) {
          this.item = { ...item };
        } else {
          this.$router.push('/inventory');
        }
      },
      updateCurrentQuantity() {
        const warehouse = this.item.warehouses.find(w => w.warehouse === this.selectedWarehouse);
        this.currentQuantity = warehouse ? warehouse.quantity : 0;
      },
      submitCount() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const index = inventoryItems.findIndex(i => i.id == this.item.id);
        if (index !== -1) {
          // Find the warehouse and update its quantity
          const warehouse = inventoryItems[index].warehouses.find(w => w.warehouse === this.selectedWarehouse);
          if (warehouse) {
            warehouse.quantity = this.physicalCount;
          }
  
          // Log the count
          inventoryItems[index].movements = inventoryItems[index].movements || [];
          inventoryItems[index].movements.push({
            date: new Date().toISOString(),
            type: 'Count',
            quantity: this.difference,
            warehouse_from: this.selectedWarehouse,
            warehouse_to: this.selectedWarehouse
          });
  
          // Save the updated inventory
          localStorage.setItem('inventoryItems', JSON.stringify(inventoryItems));
        }
        this.$router.push('/inventory');
      }
    },
    watch: {
      selectedWarehouse() {
        this.updateCurrentQuantity();
      }
    },
    created() {
      this.loadItemDetails();
    }
  }
  </script>
  
  <style scoped>
  .inventory-count {
    max-width: 600px;
    margin: auto;
  }
  </style>
  