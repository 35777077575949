<template>
  <div class="container-fluid p-4">
    <iframe
      :src="infoval"
      frameborder="0"
      style="width: 100%; height: 100vh;"
    ></iframe>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import login from "@/services/login.service";
import propertyService from "@/services/property.service"; // Asegúrate de importar tu servicio correctamente

export default {
  name: "InfoCheck",
  data() {
    return {
      infoval: null
    };
  },
  async beforeMount() {
    let mytoken = localStorage.getItem("token");
    login.checkToken(mytoken);
    console.log("mytoken: " + mytoken);
    
    let mybody = {
      token: mytoken
    };
    
    try {
      this.$store.commit("setLoading");
      let response = await propertyService.getCreditsStatus(mybody);
      this.$store.commit("setLoading");
      console.log("response", response);
      // Si no está suscrito muestra el infocheck base sin botón de informes
      /*
      if (!response.creditCardType || response.creditCardType === 'S/I' || !response.last4CardDigits || response.last4CardDigits === 'S/I') {
        this.infoval = "https://infoval.carpetainmobiliaria.com"; 
      }
      */
      if (response.informes_disponibles > 0 || response.disponibles_plan > 0) {
        this.infoval = `https://infoval.carpetainmobiliaria.com/property-finder/${mytoken}`; 
      }
      // Si está suscrito muestra el infocheck base con botón de informes 
      else {
        this.infoval = "https://infoval.carpetainmobiliaria.com"; 
      }
      console.log(this.infoval);
    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style scoped>
/* Puedes agregar estilos adicionales aquí si es necesario */
</style>

      
    
  }
};
</script>

<style scoped>
/* Puedes agregar estilos adicionales aquí si es necesario */
</style>
