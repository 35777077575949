<template>
  <b-nav vertical pills>
    <!-- Enlace para "Ingresos", visible solo si el rol es 1 -->
    <router-link class="my-nav" to="/arrival" v-if="role === 1">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-right" class="icon mr-2" />
      <p class="d-inline">Ingresos</p>
    </router-link>

    <!-- Enlace para "Egresos", visible solo si el rol es 1 -->
    <router-link class="my-nav" to="/delivery" v-if="role === 1">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-left" class="icon mr-2" />
      <p class="d-inline">Egresos</p>
    </router-link>

    <!-- Enlace para "Recepción de PM", visible solo si el rol es 3 -->
    <router-link class="my-nav" to="/receipt" v-if="role === 3">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-right" class="icon mr-2" />
      <p class="d-inline">Recepción de DM</p>
    </router-link>

    <!-- Enlace para "Entrega de PM", visible solo si el rol es 3 -->
    <router-link class="my-nav" to="/delivery" v-if="role === 3">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-left" class="icon mr-2" />
      <p class="d-inline">Entrega de DM</p>
    </router-link>

    <!-- Enlace para "Buscar trazabilidad", visible solo si el rol es 0 -->
    <router-link class="my-nav" to="/tracer" v-if="role === 0 || role === 1">
      <font-awesome-icon icon="fa-solid fa-list" class="icon mr-2" />
      <p class="d-inline">Gestión de Trazabilidad</p>
    </router-link>

    <!-- Enlace para "Buscar trazabilidad", visible solo si el rol es 0 -->
    <router-link class="my-nav" to="/inventory" v-if="role === 0 || role === 1">
      <font-awesome-icon icon="fa-solid fa-list-alt" class="icon mr-2" />
      <p class="d-inline">Gestión de Inventario</p>
    </router-link>

  </b-nav>
</template>
<script>
export default {
  name: "NavItems",
  data() {
    return {
      role: null, // Se inicializa como null
    };
  },
  computed: {
    // Computed property para verificar si el usuario es administrador
    isAdmin() {
      return this.role === 1;
    }
  },
  mounted() {
    // Se obtiene el role de localStorage
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      this.role = parseInt(storedRole, 10); // Convertir el role a número
    }
  }
};
</script>

