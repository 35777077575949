import Home from "../views/Home.vue";
import Profile from "../views/user/Profile.vue";
import CreateUnitRealtor from "../views/realtor/CreateUnitRealtor.vue";
import FolderDocuments from "../views/realtor/FolderDocuments.vue";
import PropertyDetail from "../views/globals/PropertyDetail.vue";
import PropertiesList from "../views/globals/PropertiesList.vue";
import RealtorRequests from "../views/realtor/RealtorRequests.vue";
import RequestDetail from "../views/globals/RequestDetail.vue";
import CreateRequest from "../views/realtor/CreateRequest.vue";
import CompletedDocumentsRequest from "../views/realtor/CompletedDocumentsRequest.vue";
import Report from "../views/realtor/Report.vue";
import ReportChoices from "../views/realtor/ReportChoices.vue";
import Appraiser from "../views/realtor/Appraiser.vue";
import AppraiserRequest from "../views/realtor/AppraiserRequest.vue";
import InfoCheck from "../views/realtor/InfoCheck.vue";
import ReportError from "../views/misc/ReportError.vue";
import Payment from "../views/realtor/Payment.vue";
import PaymentReturn from "../views/realtor/PaymentReturn.vue";
import PaymentReturnSuscription from "../views/realtor/PaymentReturnSuscription.vue";
import PaymentReturnSuscriptionError from "../views/realtor/PaymentReturnSuscriptionError.vue";
import PaymentCredits from "../views/realtor/PaymentCredits.vue";
import InscriptionPayment from "../views/realtor/InscriptionPayment.vue";
import PropertiesNotFound from "../views/misc/PropertiesNotFound.vue";
import guardMyRoute from "./guardMyRoute";
import CreateAssetList from "../views/globals/CreateAssetList.vue";
import CreateDeliveryList from "../views/globals/CreateDeliveryList.vue";
import InventoryList from '../views/globals/InventoryList.vue';
import InventoryEdit from '../views/globals/InventoryEdit.vue';
import InventoryDetail from '../views/globals/InventoryDetail.vue';
import InventoryAdjust from '../views/globals/InventoryAdjust.vue';
import InventoryCount from '../views/globals/InventoryCount.vue';
import MyAssetList from '../views/globals/MyAssetList.vue';
import OwnAssetList from '../views/globals/OwnAssetList.vue';
import TracerView from '../views/globals/TracerView.vue';
export default [
  {
    path: "/corredor",
    name: "Corredor",
    beforeEnter: guardMyRoute,
    redirect: "corredor/anexo",
    component: Home,
    meta: {
      title: "Crear nuevo listado",
      requiresAuth: true,
      requiresAdmin: false,
    },
    children: [
      {
        path: "/corredor/perfil",
        name: "Profile",
        component: Profile,
        meta: {
          title: "Perfil",
        },
      },
      {
        path: "/corredor/propiedades",
        name: "PropertiesList",
        component: PropertiesList,
        meta: {
          title: "Propiedades",
        },
      },
      {
        path: "/tracer",
        name: "TracerView",
        component: TracerView,
        meta: {
          title: "Historial de Trazabilidad",
        },
      },
      {
        path: "/receipt",
        name: "OwnAssetList",
        component: OwnAssetList,
        meta: {
          title: "Traspaso de propiedad",
        },
      },
      {
        path: "/myassetlist",
        name: "MyAssetList",
        component: MyAssetList,
        meta: {
          title: "Mi listado de anexos",
        },
      },
      {
        path: "/arrival",
        name: "CreateAssetList",
        component: CreateAssetList,
        meta: {
          title: "Ingreso de dispositivos",
        },
      },
      {
        path: "/delivery",
        name: "CreateDeliveryList",
        component: CreateDeliveryList,
        meta: {
          title: "Egreso de dispositivos",
        },
      },
      {
        path: "/inventory/count/:id",
        name: "count-inventory",
        component: InventoryCount,
        meta: {
          title: "Contar inventario",
        },
      },
      {
        path: "/inventory/adjust/:id",
        name: "adjust-inventory",
        component: InventoryAdjust,
        meta: {
          title: "Modificar inventario",
        },
      },
      {
        path: "/inventory/edit/:id",
        name: "edit-inventory",
        component: InventoryEdit,
        meta: {
          title: "Editar inventario",
        },
      },
      {
        path: "/inventory/add",
        name: "add-inventory",
        component: InventoryEdit,
        meta: {
          title: "Agregar artículo al inventario",
        },
      },
      {
        path: "/inventory/detail/:id",
        name: "inventory-detail",
        component: InventoryDetail,
        meta: {
          title: "Detalle de inventario",
        },
      },
      {
        path: "/inventory",
        name: "inventory-list",
        component: InventoryList,
        meta: {
          title: "Lista de inventario",
        },
      },
      {
        path: "/corredor/propiedades/informe",
        name: "Report",
        component: Report,
        props: true,
        meta: {
          title: "Informe",
        },
      },
      {
        path: "/corredor/propiedades/informe/error-informe",
        name: "ReportError",
        component: ReportError,
        props: true,
        meta: {
          title: "¡Error!",
        },
      },
      {
        path: "/corredor/propiedades/opciones-informe",
        name: "ReportChoices",
        component: ReportChoices,
        props: true,
        meta: {
          title: "Elige tu informe",
        },
      },
      {
        path: "/corredor/propiedades/valorizador",
        name: "Appraiser",
        component: Appraiser,
        props: true,
        meta: {
          title: "Valoriza tu propiedad",
        },
      },
      {
        path: "/corredor/pago",
        name: "Payment",
        component: Payment,
        props: true,
        meta: {
          title: "Compra informes",
        },
      },
      {
        path: "/corredor/propiedades/solicitartasador",
        name: "AppraiserRequest",
        component: AppraiserRequest,
        props: true,
        meta: {
          title: "Solicita un experto inmobiliario",
        },
      },
      {
        path: "/corredor/propiedades/opciones-informe/pago-informe",
        name: "InscriptionPayment",
        component: InscriptionPayment,
        props: true,
        meta: {
          title: "Pago de Informe Premium",
        },
      },
      {
        path: "/corredor/propiedades/ingresar-propiedad",
        name: "CreateUnitRealtor",
        component: CreateUnitRealtor,
        meta: {
          title: "Ingresar una propiedad",
        },
      },
      {
        path: "/corredor/propiedades/propiedad-no-encontrada",
        name: "PropertiesNotFound",
        component: PropertiesNotFound,
        meta: {
          title: "¡Propiedad no encontrada!",
        },
      },
      {
        path: "/corredor/propiedades/:unit",
        name: "PropertyDetail",
        component: PropertyDetail,
        props: true,
        meta: {
          title: "Propiedad",
        },
      },
      {
        path: "/corredor/propiedades/:unit/documentos",
        name: "FolderDocuments",
        component: FolderDocuments,
        props: true,
        meta: {
          title: "Documentos de propiedad",
        },
      },
      {
        path: "/corredor/solicitudes",
        name: "RealtorRequests",
        component: RealtorRequests,
        meta: {
          title: "Solicitudes",
        },
      },
      {
        path: "/corredor/solicitudes/detalle-solicitud",
        name: "RequestDetail",
        props: true,
        component: RequestDetail,
        meta: {
          title: "Solicitud",
        },
      },
      {
        path: "/corredor/solicitudes/crear-solicitud",
        name: "CreateRequest",
        component: CreateRequest,
        meta: {
          title: "Crear una solicitud",
        },
      },
      {
        path: "/corredor/solicitudes/solicitud-completada",
        name: "CompletedDocumentsRequest",
        component: CompletedDocumentsRequest,
        meta: {
          title: "Solicitud completada",
        },
      },
      {
        path: "/corredor/infocheck",
        name: "InfoCheck",
        component: InfoCheck,
        meta: {
          title: "Buscar propiedades",
        },
      },
      {
        path: "/corredor/retorno/:uuid",
        name: "PaymentReturn",
        component: PaymentReturn,
        props: true,
        meta: {
          title: "Estado del pago",
        },
      },
      {
        path: "/corredor/retornosus/:uuid",
        name: "PaymentReturnSuscription",
        component: PaymentReturnSuscription,
        props: true,
        meta: {
          title: "Estado de la suscripción",
        },
      },
      {
        path: "/corredor/creditos",
        name: "PaymentCredits",
        component: PaymentCredits,
        meta: {
          title: "Mis créditos",
        },
      },
      {
        path: "/corredor/retornosuserror/:uuid",
        name: "PaymentReturnSuscriptionError",
        component: PaymentReturnSuscriptionError,
        props: true,
        meta: {
          title: "Error en la suscripción",
        },
      }
    ],
  },
];
