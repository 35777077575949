import jwtDecode from 'jwt-decode';
import store from '@/store';

export default function guardMyRoute(to, from, next) {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const isExpired = decoded.exp < Date.now() / 1000;

      if (isExpired) {
        // Si el token está expirado, redirigir a la página de login
        localStorage.removeItem("token");
        store.commit('logout');
        next({ name: "Login" });
        return; // Detener el flujo después de redirigir
      }

      // Si el token es válido, guardar información del usuario
      localStorage.setItem('email', decoded.email);
      localStorage.setItem('role', decoded.roleId);

      // Verificar si la ruta requiere autenticación
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.getIsLoggedIn) {
          // Si el usuario no está logueado, redirigir al login
          next({ name: "Login" });
          return; // Detener el flujo
        } else {
          // Redirigir según el roleId
          if (decoded.roleId === 1 && to.name !== "CreateAssetList") {
            next({ name: "CreateAssetList" });
            return;
          } else if (decoded.roleId === 3 && to.name !== "OwnAssetList") {
            next({ name: "OwnAssetList" });
            return;
          } else if (decoded.roleId === 0 && to.name !== "TracerView") {
            next({ name: "TracerView" });
            return;
          } 
          else {
            next(); // Continuar si la ruta es la correcta
          }
        }
      } else if (to.matched.some(record => record.meta.hideForAuth)) {
        // Si la ruta debe ocultarse después de login, redirigir al inicio adecuado
        if (store.getters.getIsLoggedIn) {
          if (decoded.roleId === 1 && to.name !== "CreateAssetList") {
            next({ name: "CreateAssetList" });
            return;
          } else if (decoded.roleId === 3 && to.name !== "OwnAssetList") {
            next({ name: "OwnAssetList" });
            return;
          } else if (decoded.roleId === 0 && to.name !== "TracerView") {
            next({ name: "TracerView" });
            return;
          } else {
            next(); // Continuar si ya está autenticado
          }
        } else {
          next();
        }
      } else {
        next();
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      next({ name: "Login" });
    }
  } else {
    // Si no hay token y la ruta requiere autenticación, redirigir al login
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
}
