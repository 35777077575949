<template>
    <div class="inventory-detail">
      <b-card :title="item.name">
        <b-list-group>
          <b-list-group-item><strong>EAN:</strong> {{ item.ean }}</b-list-group-item>
          <b-list-group-item><strong>SKU:</strong> {{ item.sku }}</b-list-group-item>
          <b-list-group-item><strong>Lote:</strong> {{ item.lote }}</b-list-group-item>
          <b-list-group-item><strong>Vencimiento:</strong> {{ item.expiration }}</b-list-group-item>
        </b-list-group>
      </b-card>
  
      <!-- Table for Movements -->
      <b-card title="Registro de Movimientos" class="mt-3">
        <b-table striped hover :items="movements" :fields="movementFields">
          <template #cell(date)="data">
            {{ new Date(data.item.date).toLocaleDateString() }}
          </template>
        </b-table>
      </b-card>
  
      <!-- Table for Quantity Adjustments -->
      <b-card title="Ajustes de Cantidad" class="mt-3">
        <b-table striped hover :items="adjustments" :fields="adjustmentFields">
          <template #cell(date)="data">
            {{ new Date(data.item.date).toLocaleDateString() }}
          </template>
        </b-table>
      </b-card>
  
      <!-- Back Button -->
      <b-button @click="$router.push('/inventory')" variant="primary" class="mt-3">Volver</b-button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        item: {
          name: '',
          ean: '',
          sku: '',
          lote: '',
          expiration: '',
          movements: [] // Log of movements
        },
        movementFields: [
          { key: 'date', label: 'Fecha' },
          { key: 'type', label: 'Tipo de Movimiento' },
          { key: 'quantity', label: 'Cantidad' },
          { key: 'warehouse_from', label: 'Desde' },
          { key: 'warehouse_to', label: 'Hacia' }
        ],
        adjustmentFields: [
          { key: 'date', label: 'Fecha' },
          { key: 'quantity', label: 'Cantidad Ajustada' },
          { key: 'warehouse', label: 'Bodega' }
        ]
      };
    },
    computed: {
      movements() {
        return this.item.movements.filter(movement => movement.type === 'Movimiento');
      },
      adjustments() {
        return this.item.movements.filter(movement => movement.type === 'Cantidad Ajustada');
      }
    },
    methods: {
      loadItemDetails() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const item = inventoryItems.find(i => i.id === this.$route.params.id);
        if (item) {
          this.item = { ...item };
        } else {
          this.$router.push('/inventory');
        }
      }
    },
    created() {
      this.loadItemDetails();
    }
  }
  </script>
  
  <style scoped>
  .inventory-detail {
    max-width: 600px;
    margin: auto;
  }
  </style>
  
  