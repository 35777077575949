<template>
  <div class="d-flex">
    <div class="vh-100 bg-white auth-content d-flex align-items-center">
      <b-container class="mt-5">
        <b-row class="justify-content-between">
          <b-col lg="8" class="mx-auto">
            <div>
              <img src="../../assets/logo-trazamed-green.svg" alt="" class="mb-5"/> <small class="mr-1"><b>Trace smart</b></small>
              <h2 class="text-dark mb-3">Ubica tus activos!</h2>
              <p class="mb-5">Ingresa tus datos aqui</p>
              <form-login/>
              <!--
              <p class="text-center text-dark align-items-center" style="font-size:12px;">2023 iProp® by Inciti®. Todos los derechos reservados.</p>
              -->
              <p class="text-center text-dark align-items-center" style="font-size:12px;">
                {{ currentYear }} Trazamed®. Todos los derechos reservados.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="vh-100 bg-login d-none d-lg-flex align-items-center">
      <b-container class="mt-5">
        <b-row>
          <b-col lg="10" class="mx-auto">
            <h4 class="text-black mb-5">Tus activos siempre ubicables</h4>
            <h1 class="text-black">Trazabilidad en <span class="text-primary"> hospitales y clínicas</span> en tiempo real</h1>
            <img src="../../assets/img-login-new.gif" alt="" class="d-block img-fluid">
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import FormLogin from "@/components/forms/FormLogin.vue";
export default {
  name: "UserLogin",
  components: { FormLogin },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

