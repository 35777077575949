<template>
    <div class="timeline">
      <div v-for="(event, index) in items" :key="index" class="timeline-item">
        <div class="timeline-date">{{ event.formattedDate }}</div>
        <div class="timeline-content">
          <strong>Propietario:</strong> {{ event.owner }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Timeline",
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .timeline {
    border-left: 2px solid #007bff;
    padding-left: 20px;
    position: relative;
  }
  .timeline-item {
    margin-bottom: 20px;
  }
  .timeline-date {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .timeline-content {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  </style>
  