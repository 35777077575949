<template>
    <div class="inventory-edit">
      <b-card :title="item.id ? 'Editar Artículo de Inventario' : 'Agregar Nuevo Artículo'">
        <b-form @submit.prevent="submitForm">
          <b-form-group label="Nombre del Producto" label-for="input-name">
            <b-form-input id="input-name" v-model="item.name" required></b-form-input>
          </b-form-group>
  
          <!-- Nuevo Campo: Fabricante -->
          <b-form-group label="Fabricante" label-for="input-manufacturer">
            <b-form-input id="input-manufacturer" v-model="item.manufacturer" required></b-form-input>
          </b-form-group>
  
          <b-form-group label="Categoría" label-for="input-category">
            <b-form-select id="input-category" v-model="item.category" :options="categoryOptions" required></b-form-select>
          </b-form-group>
          
          <b-form-group label="Código EAN" label-for="input-ean">
            <b-form-input id="input-ean" v-model="item.ean"></b-form-input>
          </b-form-group>
          
          <b-form-group label="Código SKU" label-for="input-sku">
            <b-form-input id="input-sku" v-model="item.sku"></b-form-input>
          </b-form-group>
          
          <b-form-group label="Número de Lote" label-for="input-lote">
            <b-form-input id="input-lote" v-model="item.lote"></b-form-input>
          </b-form-group>
          
          <b-form-group label="Fecha de Vencimiento" label-for="input-expiration">
            <b-form-input type="date" id="input-expiration" v-model="item.expiration" required></b-form-input>
          </b-form-group>
  
          <!-- Nueva Sección: Cantidad por Bodegas -->
          <b-card title="Cantidad por Bodegas">
            <b-form-group label="Bodega Full Space" label-for="input-full-space">
              <b-form-input type="number" id="input-full-space" v-model.number="warehouses.full_space" required></b-form-input>
            </b-form-group>
            <b-form-group label="Bodega Kardex Hospitalizado" label-for="input-kardex-hospitalizado">
              <b-form-input type="number" id="input-kardex-hospitalizado" v-model.number="warehouses.kardex_hospitalizado" required></b-form-input>
            </b-form-group>
            <b-form-group label="Bodega Kardex Ambulatorio" label-for="input-kardex-ambulatorio">
              <b-form-input type="number" id="input-kardex-ambulatorio" v-model.number="warehouses.kardex_ambulatorio" required></b-form-input>
            </b-form-group>
            <b-form-group label="Bodega Recetas de Alta" label-for="input-recetas-alta">
              <b-form-input type="number" id="input-recetas-alta" v-model.number="warehouses.recetas_alta" required></b-form-input>
            </b-form-group>
            <b-form-group label="Bodega Mermas" label-for="input-mermas">
              <b-form-input type="number" id="input-mermas" v-model.number="warehouses.mermas" required></b-form-input>
            </b-form-group>
            <b-form-group label="Bodega Cuarentena" label-for="input-cuarentena">
              <b-form-input type="number" id="input-cuarentena" v-model.number="warehouses.cuarentena" required></b-form-input>
            </b-form-group>
          </b-card>
  
          <b-form-group label="Estado del Artículo" label-for="input-status">
            <b-form-select v-model="item.status" :options="statusOptions" required></b-form-select>
          </b-form-group>
  
          <b-button type="submit" variant="success">{{ item.id ? 'Guardar Cambios' : 'Agregar Artículo' }}</b-button>
          <b-button @click="$router.push('/inventory')" variant="danger" class="ml-2">Cancelar</b-button>
        </b-form>
      </b-card>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        item: {
          id: this.$route.params.id || null,
          name: '',
          manufacturer: '', // Nuevo campo para el fabricante
          ean: '',
          sku: '',
          lote: '',
          expiration: '',
          status: 'operativo',
          category: ''
        },
        warehouses: {
          full_space: 0,
          kardex_hospitalizado: 0,
          kardex_ambulatorio: 0,
          recetas_alta: 0,
          mermas: 0,
          cuarentena: 0
        },
        statusOptions: [
          { value: 'operativo', text: 'Operativo' },
          { value: 'mermado', text: 'Mermado' },
          { value: 'vencido', text: 'Vencido' }
        ],
        categoryOptions: [
          { value: 'farmacos', text: 'Fármacos' },
          { value: 'ministeriales', text: 'Arsenal Ministeriales' },
          { value: 'ocasionales', text: 'Arsenal Ocasionales' },
          { value: 'controlados', text: 'Arsenal Controlados' },
          { value: 'insumos', text: 'Insumos' }
        ]
      };
    },
    methods: {
      submitForm() {
        const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
        const newItem = {
          ...this.item,
          warehouses: [
            { warehouse: 'full_space', quantity: this.warehouses.full_space },
            { warehouse: 'kardex_hospitalizado', quantity: this.warehouses.kardex_hospitalizado },
            { warehouse: 'kardex_ambulatorio', quantity: this.warehouses.kardex_ambulatorio },
            { warehouse: 'recetas_alta', quantity: this.warehouses.recetas_alta },
            { warehouse: 'mermas', quantity: this.warehouses.mermas },
            { warehouse: 'cuarentena', quantity: this.warehouses.cuarentena }
          ]
        };
  
        if (this.item.id) {
          const index = inventoryItems.findIndex(i => i.id === this.item.id);
          if (index !== -1) {
            inventoryItems[index] = { ...newItem };
          }
        } else {
          newItem.id = inventoryItems.length + 1;
          inventoryItems.push(newItem);
        }
  
        localStorage.setItem('inventoryItems', JSON.stringify(inventoryItems));
        this.$router.push('/inventory');
      },
      loadItemDetails() {
        if (this.item.id) {
          const inventoryItems = JSON.parse(localStorage.getItem('inventoryItems')) || [];
          const item = inventoryItems.find(i => i.id === this.item.id);
          if (item) {
            this.item = { ...item };
            // Load warehouse quantities into the form fields
            item.warehouses.forEach(warehouse => {
              this.warehouses[warehouse.warehouse] = warehouse.quantity;
            });
          } else {
            this.$router.push('/inventory');
          }
        }
      }
    },
    created() {
      this.loadItemDetails();
    }
  };
  </script>
  
  <style scoped>
  .inventory-edit {
    max-width: 600px;
    margin: auto;
  }
  </style>
  