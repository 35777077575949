import axios from 'axios';
import Config from "@/../config.json";
import jwtDecode from 'jwt-decode';

const login = {
  async getLogin(values) {
    const data = await axios.post(
      `${Config.TRAZAMED_APP_URL_BASE}/login`,
      values
      ) 
      .catch((err) => { return (err.response.data.message) })
      ;
    //alert("data: " + data.data.message);
    return data;

  },
  checkToken(token) {
    try {
      if (token) {
        const decoded = jwtDecode(token);
        console.log("token dec: ");
        console.log(decoded);
        const isExpired = decoded.exp < Date.now() / 1000;
    
        if (isExpired) {
          localStorage.removeItem("token"); // Remover el token expirado
          store.commit('logout'); // Asegurarse de limpiar el estado de autenticación
          next({ name: "Login" });
          return; // Salir de la función para evitar llamadas adicionales a next
        }
        else {
          localStorage.setItem('email', decoded.email);
          localStorage.setItem('role', decoded.roleId);
          return;
        }
      }
    }
    catch (error) {
      console.log(error);
      localStorage.removeItem("token");
      store.commit('logout');
      next({ name: "Login" });
      return;
    }
  }
};
export default login;
