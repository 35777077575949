<template>
  <div class="container">
    <b-card>
      <h4>Crear una lista de entrega de dispositivos médicos (DM)</h4>
      <b-card-text>Agrega DM's al listado escaneando el código QR o código de barras de cada uno</b-card-text>
      <div>
        <!-- Selector de cámara -->
        <div class="mb-2 mt-2">
          <label for="cameraSelection">Seleccionar Cámara:</label>
          <select id="cameraSelection" @change="changeCamera" v-model="selectedCameraId" class="form-control d-inline-block w-auto">
            <option v-for="device in videoDevices" :key="device.id" :value="device.id">
              {{ device.label || `Cámara ${device.id}` }}
            </option>
          </select>
        </div>
        <!-- Botón para iniciar el escaneo -->
        <b-button @click="startScanning" variant="primary"><i class="fa fa-qrcode"></i> Escanear DM</b-button>
      </div>

      <!-- Contenedor del lector -->
      <div v-show="selectCameraMode" class="text-center mt-3">
        <div id="qr-reader" class="video-fluid"></div>
        <!-- Botón para detener el escaneo -->
        <div class="mt-3 text-center">
          <b-button @click="stopScanning" variant="secondary" class="w-100">
            Detener Escaneo
          </b-button>
        </div>
      </div>

      <!-- Lista de activos escaneados -->
      <div v-if="scannedAssets.length" class="mt-4">
        <h5>Dispositivos Médicos Seleccionados:</h5>
        <b-table :items="scannedAssets" :fields="fields" responsive="sm">
          <template #cell(actions)="data">
            <button @click="deleteItem(data.index)" class="btn btn-danger ml-1">
              <i class="fa fa-trash"></i>
            </button>
          </template>
        </b-table>
        <b-button @click="generateTransferQr" variant="success">Generar QR de Traspaso</b-button>
      </div>

      <!-- Mostrar QR de traspaso -->
      <div v-if="transferQrContent" class="qr-general mt-4">
        <vue-qrcode :text="transferQrContent" :size="200"></vue-qrcode>
        <p class="mt-2">Pida a otro usuario que escanee este código para recibir los DM's.</p>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import VueQrcodeComponent from 'vue-qrcode-component';
import { Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeSupportedFormats } from "html5-qrcode";
import Config from "@/../config.json";

export default {
  components: {
    VueQrcode: VueQrcodeComponent
  },
  data() {
    return {
      scannedAssets: [],
      selectedCameraId: null,
      videoDevices: [],
      transferQrContent: '',
      fields: [
        { key: 'Dispositivo', label: 'Dispositivo' },
        { key: 'SN', label: 'SN' },
        { key: 'LN', label: 'LN' },
        { key: 'FV', label: 'FV' },
        { key: 'owner', label: 'Owner' },
        { key: 'actions', label: 'Acciones' }
      ],
      selectCameraMode: false,
      qrCodeScanner: null,
      token: localStorage.getItem('token'),
      isProcessingQR: false
    };
  },
  methods: {
    async getAvailableCameras() {
      try {
        const devices = await Html5Qrcode.getCameras();
        this.videoDevices = devices;

        if (this.videoDevices.length > 0 && !this.selectedCameraId) {
          this.selectedCameraId = this.videoDevices[0].id;
        }
      } catch (error) {
        console.error("Error obteniendo cámaras:", error);
      }
    },
    async startScanning() {
      this.selectCameraMode = true;
      this.isProcessingQR = false; // Bandera para controlar el procesamiento

      await this.$nextTick();

      if (this.qrCodeScanner) {
        await this.stopScanning();
      }

      const qrReaderElement = document.getElementById("qr-reader");
      if (!qrReaderElement) {
        console.error("El elemento 'qr-reader' no existe en el DOM.");
        return;
      }

      this.qrCodeScanner = new Html5Qrcode("qr-reader");

      const config = { 
        fps: 10, 
        qrbox: 250,
        formatsToSupport: [
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.EAN_13,
          // Agrega otros formatos si es necesario
        ]
      };

      try {
        await this.qrCodeScanner.start(
          this.selectedCameraId,
          config,
          async (decodedText, decodedResult) => {
            console.log("Código escaneado: ", decodedText);
            console.log("Formato del código: ", decodedResult.result.format.formatName);

            if (this.isProcessingQR) {
              // Si ya se está procesando otro código, ignorar este
              return;
            }

            this.isProcessingQR = true; // Establecer que se está procesando un código

            const codeContent = decodedText.trim();
            const formatName = decodedResult.result.format.formatName;

            // Obtener detalles del activo por el código escaneado
            try {
              let response;
              
              // Si es un código QR, usamos wiliot_id
              response = await axios.post(
                `${Config.TRAZAMED_APP_URL_BASE}/getAssetDetails`,
                { wiliot_id: codeContent },
                {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                  },
                }
              );
            
              if (response.data) {
                const asset = response.data;
                // Desestructurar el objeto details para agregarlo a la tabla
                const assetData = {
                  ...asset.details,
                  owner: asset.owner,
                  assetuuid: asset.assetuuid,
                  wiliot_id: asset.wiliot_id,
                };
                // Verificar si el activo ya está en la lista
                const exists = this.scannedAssets.some(
                  (a) => a.assetuuid === asset.assetuuid
                );
                if (!exists) {
                  this.scannedAssets.push(assetData);
                } else {
                  alert("Este activo ya ha sido agregado.");
                }
              }
            } catch (error) {
              console.error("Error obteniendo detalles del activo:", error);
              alert("Error obteniendo detalles del activo.");
            } finally {
              // Restablecer la bandera después de procesar el código
              this.isProcessingQR = false;
            }
          },
          (errorMessage) => {
            console.warn("Error de escaneo: ", errorMessage);
          }
        );
      } catch (err) {
        console.error("Error iniciando el lector: ", err);
      }
    },

    async stopScanning() {
      if (this.qrCodeScanner) {
        await this.qrCodeScanner.stop();
        this.qrCodeScanner.clear();
        this.qrCodeScanner = null;
      }
      this.selectCameraMode = false;
    },
    async changeCamera() {
      if (this.selectCameraMode) {
        await this.stopScanning();
        this.startScanning();
      }
    },
    async generateTransferQr() {
      if (this.scannedAssets.length === 0) {
        alert('No hay activos seleccionados.');
        return;
      }

      try {
        // Hacemos la llamada a storeAssetList para almacenar la lista y obtener el uuid correcto
        const response = await axios.post(`${Config.TRAZAMED_APP_URL_BASE}/storeAssetList`, { asset_list: this.scannedAssets }, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        });

        // Verificamos que la respuesta contiene el uuid de la lista almacenada
        if (response.data.listuuid) {
          const listuuid = response.data.listuuid;
          console.log("UUID recibido desde storeAssetList:", listuuid);

          // Generamos el contenido del QR con el listuuid correcto
          this.transferQrContent = JSON.stringify({ listuuid });
          console.log("Contenido QR generado:", this.transferQrContent);
        } else {
          console.error('Error: la respuesta no contiene un listuuid.');
          alert('Error generando QR de traspaso.');
        }
      } catch (error) {
        console.error('Error generando QR de traspaso:', error);
        alert('Error generando QR de traspaso.');
      }
    },
    deleteItem(index) {
      this.scannedAssets.splice(index, 1);
    }
  },
  created() {
    this.getAvailableCameras();
  },
  beforeDestroy() {
    if (this.qrCodeScanner) {
      this.qrCodeScanner.clear();
      this.qrCodeScanner = null;
    }
  }
};
</script>

<style scoped>
#qr-reader {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.video-fluid {
  width: 100%;
  height: auto;
}
.qr-general {
  margin: 20px 0;
}
</style>
